import React from 'react';
const GalleryImageCard = ({ image, name ,price,day}) => {
    return (
      <div className="app__gallery-images_card flex__center">
      <img src={image} alt="gallery_image" />
      <div className="gallery__image-details">
        <p className="gallery__image-name">{name}</p>
        <p className="gallery__image-days">{day} </p>
        <p className="gallery__image-price">${price}</p>
        
      </div>
    </div>
    );
  };

  export default GalleryImageCard