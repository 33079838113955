import React from 'react';
import {  BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import GalleryImageCard from './GalleryImageCard';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Drinks.css';
const imagesData = [
  { image: images.beer, name: 'Molson Products',price:'4.50',day:'Monday' },
  { image: images.tankard, name: 'Tankards',price:'10',day:'Tuesday' },
  { image: images.cocktails, name: 'Speciality Cocktails',price:'5',day:'Wednesday' },
  { image: images.tequilla, name: 'Tequila & Margaritas' ,price:'5.25',day:'Thursday'},
 
  { image: images.shooters, name: 'Shooters',price:'5',day:'Friday' },
  { image: images.coolers, name: 'Coolers',price:'5.25',day:'Saturday' },
  { image: images.caesars, name: 'Caesars',price:'5',day:'Sunday' },
 
  
 
];
const Drinks = () => {

  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center" id="drinks">
      <div className="app__gallery-content">
        <SubHeading title="Drinks Menu" />
        <h1 className="headtext__cormorant">Weekly Special Drinks</h1>
        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>
        At Dragons Den Pub, enjoy a variety of drinks with a daily special twist. Savor crafted
cocktails, refreshing beers, and fine wines at unbeatable prices. From Molson Mondays to
Tequila Thursdays, find your new favorite drink at a special price!
</p>
{/* <button type="button" className="custom__button">View More</button> */}
      </div>

      
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {imagesData.map((imageData, index) => (
            <GalleryImageCard key={`gallery_image-${index + 1}`} {...imageData} />
          ))}
        </div>

        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Drinks;
