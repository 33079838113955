import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
// @ts-ignore
// import Menu from '../../assets/Mike_rest-menu.pdf';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Welcome to Dragons Den Pub" />
      <h1 className="app__header-h1">Your Ultimate Spot for Good Times!</h1>
      {/* <p className="p__opensans" style={{ margin: '2rem 0' }}>
      </p>     */}
      </div>

    <div className="app__wrapper_img">
      <img src={images.bar} alt="header_img" />
    </div>
  </div>
);

export default Header;
