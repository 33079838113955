import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import Menu from '../../assets/Mike_rest-menu.pdf';
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#about">About</a></li>
        <li className="p__opensans"><a href="#events">Events</a></li>
        <li className="p__opensans"><a href="#drinks">Drinks</a></li>
        <li className="p__opensans"><a href={Menu} target="_blank"
                    rel="noreferrer"> Menu</a></li>
        
        
        <li className="p__opensans"><a href="#contact">Contact</a></li>
        
      </ul>
      <div className="app__navbar-login">
        {/* <a href="#login" className="p__opensans">Log In / Registration</a> */}
        <div />
        <a href="#contact" className="p__opensans"><h2>Book Event</h2></a>
        {/* <p className="p__opensans" style={{fontSize:'20px' }}>+1 (807) 767-6999</p> */}

        <h2 className="blink"
                    style={{ color: "beige" }}> 
                   +1 (807) 767-6999
                </h2> 
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>About</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
              {/* <li><a href="#awards" onClick={() => setToggleMenu(false)}>Awards</a></li> */}
              <li><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
