import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Navbar } from './components';
import Header from './container/Header/Header'
import SpecialMenu from './container/Menu/SpecialMenu'
import AboutUs from './container/AboutUs/AboutUs'
import Gallery from './container/Gallery/Gallery';
import Drinks from './container/Drinks/Drinks';
import FindUs from './container/Findus/FindUs'
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar/>
        <Header />
        <AboutUs/>
       <SpecialMenu/>
       <Drinks/>
       <Gallery/>
       <FindUs/>
       
      </div>
    </Router>
  );
}

export default App;
