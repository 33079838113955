import React from 'react';

import { SubHeading } from '../../components';
// import { images } from '../../constants';
// import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';
const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Contact Us</h1>
      <div className="app__wrapper-content">
     
      <p className="p__opensans"> Contact us for event bookings, table reservations, or any inquiries you have. Our team is here to ensure your experience is seamless and memorable.</p>
     <br></br>
     <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Address</p>
     <p className="p__opensans" style={{fontSize:'20px'}}>1010 Dawson Rd, Thunder Bay, ON P7B 5J4</p>
     <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Phone Number</p>
     <p className="p__opensans" style={{fontSize:'20px' }}>+1 (807) 767-6999</p>
     <p className="p__opensans" style={{fontSize:'20px' }}>+1 (807) 626-4490</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
        <p className="p__opensans">Sun - Wed: 11:00 am - 01:00 am</p>
        <p className="p__opensans">Thru - Sat: 11:00 am - 02:00 am</p>
        

      </div>



      
    </div>
   
    <div className="app__wrapper_img">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5292.488426281647!2d-89.25351952371892!3d48.4518436712799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d59216b88e965cb%3A0xb059494ea965ed2a!2sDragon&#39;s%20Den%20Sports%20Bar%20%26%20Grill!5e0!3m2!1sen!2sca!4v1713727040348!5m2!1sen!2sca"
       width="600" height="450" 
      
        >

        </iframe>
   
    </div>
  </div>
);

export default FindUs;
