import React from 'react';

import { SubHeading } from '../../components';
// import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  



  <div className="app__bg app__wrapper section__padding" id="events">

  <div 
  className="app__wrapper_img app__wrapper_img-reverse"
  >
 <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdragonsdensbar%2F%3Futm_source%3Drestaurantguru%26utm_medium%3Dreferral&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="500" 
//  style="border:none;overflow:hidden"
  // scrolling="no" frameborder="0" allowfullscreen="true"
  allow="autoplay; clipboard-write;
   encrypted-media; picture-in-picture; 
   web-share"></iframe>
    {/* <img src={images.bottle} alt="chef_image" /> */}
  </div>
  <div className="app__wrapper_info">
  <h1 className="headtext__cormorant">Events & Shows</h1>
    <SubHeading title="Exciting events for every single day" />
   

    
        <ul><li className="p__opensans">Singles Nights</li>



        <li className="p__opensans">Live Music Fridays</li>


        <li className="p__opensans">90s Night</li>


        <li className="p__opensans">Karaoke Nights</li>


        <li className="p__opensans">Comedy Shows</li>


        <li className="p__opensans">Themed Parties</li>


        {/* <li className="p__opensans">Experience the Best in Entertainment Every Visit!</li> */}

        </ul>






      {/* </div> */}
     
      {/* <p className="p__opensans"> auctor sit iaculis in arcu. Vulputate nulla lobortis mauris eget sit. Nulla scelerisque scelerisque congue ac consequat, aliquam molestie lectus eu. Congue iaculis integer curabitur semper sit nunc. </p> */}
    {/* </div> */}
    {/* <div class="center-container"> */}
    <button type="button" className="custom__button center-container" style={{marginTop:"20px" , height:"90px"}}>
     

<a href="#contact" className="custom__button"  style={{fontSize:"25px"}}> Contact Us For Next Event</a>
                </button>
                {/* </div> */}
    {/* <div className="app__chef-sign"> */}
      {/* <p>Mike</p> */}
   
      {/* <p className="p__opensans"> Founder</p> */}
      {/* <img src={images.sign} alt="sign_image" /> */}
    {/* </div> */}
  </div>
</div>
);

export default SpecialMenu;
