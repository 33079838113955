// @ts-nocheck
import bg from '../assets/bg.png';
// import chef from '../assets/chef.png';
// import G from '../assets/G.png';
// import gallery01 from '../assets/gallery01.png';
// import gallery02 from '../assets/gallery02.png';
// import gallery03 from '../assets/gallery03.png';
// import gallery04 from '../assets/gallery04.png';
// import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
// import welcome from '../assets/welcome.png';
// import findus from '../assets/findus.png';
// import laurels from '../assets/laurels.png';
// import award01 from '../assets/award01.png';
// import award02 from '../assets/award02.png';
// import award03 from '../assets/award03.png';
// import award05 from '../assets/award05.png';
// import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
// import gericht from '../assets/gericht.png';
import skinpotatoes from '../assets/skinpotatoes.jpg';
import bar from '../assets/bar.jpg';
import bartender from '../assets/bartender.jpg';
import cheesecake from '../assets/cheesecake.png';
import steak from '../assets/steak.jpg';
import burgerbrisket from '../assets/burgerbrisket.png'
import bottle from '../assets/bottle.jpg'
import cocktail from '../assets/cocktail.jpg'
import poutine from '../assets/poutine.png'
import pizza from '../assets/pizza.png'
import BLT from '../assets/BLT.png'
import beer from '../assets/beer.png'


import sphagehti  from '../assets/sphagehti.png'
import fish  from '../assets/fish.png'
import RIBS  from '../assets/RIBS.png'


import tequilla from '../assets/tequilla.png'
import tankard  from '../assets/tankard.png'
import shooters from '../assets/shooters.png'
import caesars from '../assets/caesars.png'
import cocktails from '../assets/cocktails.png'
import coolers from '../assets/coolers.png'
export default {
  bg,
  tequilla,
   tankard,
   shooters,
    caesars,
    cocktails,
    coolers,
  logo,
  menu,
  overlaybg,
  spoon,
  // welcome,
  // findus,
  
  // sign,
  quote,
  // gericht,
  skinpotatoes,
  bar,
  bartender,
  cheesecake,
  steak,
  burgerbrisket,
  
  bottle,
  cocktail,
  beer,
  BLT,
  pizza,
  poutine,


   
  sphagehti,
 fish, 
  RIBS
};
