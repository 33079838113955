import React from 'react';


import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__bg app__wrapper section__padding" id="about">

    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.bottle} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
    <h1 className="headtext__cormorant">About Us</h1>
      {/* <SubHeading title="What we believe in" /> */}
     

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          {/* <img src={images.quote} alt="quote_image" /> */}
          <p className="p__opensans" style={{fontSize:'20px'}} >
          Welcome to Dragons Den Pub! Located in the heart of the city, we offer exceptional food,
refreshing drinks, and lively games. Enjoy themed nights, live music, and exciting events.
Perfect for casual drinks or special celebrations, Dragons Den Pub is your spot for unforgettable
nights. Join us!</p>
        </div>
        {/* <p className="p__opensans"> auctor sit iaculis in arcu. Vulputate nulla lobortis mauris eget sit. Nulla scelerisque scelerisque congue ac consequat, aliquam molestie lectus eu. Congue iaculis integer curabitur semper sit nunc. </p> */}
      </div>

    
    </div>
  </div>
);

export default AboutUs;
