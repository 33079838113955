import React from 'react';
import {  BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';
import Menu from '../../assets/Mike_rest-menu.pdf';
const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app__gallery-content">
        <SubHeading title="Menu" />
        <h1 className="headtext__cormorant">Food Gallery</h1>
        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>
        Explore our enticing menu with crispy chicken wings, juicy burgers, and indulgent poutine.
Save room for delectable desserts like brownies and cheesecakes. Don’t miss our perfectly
baked pizzas. Every visit promises a delightful culinary adventure.</p>
        {/* <button type="button" className="custom__button">View More</button> */}
        <button type="button" className="custom__button">
      <a href={Menu} target="_blank"
                    rel="noreferrer"> 
                   Explore Menu
                </a> 
                </button>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.burgerbrisket, images.sphagehti,images.BLT,images.fish, images.RIBS,images.pizza,images.cheesecake,images.poutine].map((image, index) => (
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              {/* <BsInstagram className="gallery__image-icon" /> */}
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
